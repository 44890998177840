<template>
  <div class="vlt-popup" :style="backgroundStyle" @click="closePopupEvent()">
    <div class="popup-box" :style="[{ width, top},propStyle]" ref="popupBox" @click.stop>
      <div class="box-header" v-if="!floater">
        <slot name="header"></slot>
        <img
          v-if="displayBack"
          class="back-img"
          src="@/assets/icons/Back.svg"
          alt="Back"
          @click="actBack"
          loading="lazy"
        />
        <img
          src="@/assets/icons/close.svg"
          alt="closes"
          class="header-close"
          @click="closePopupEvent()"
          loading="lazy"
        />
        <div class="clearfix"></div>
      </div>
      <slot name="message"></slot>
      <slot></slot>
    </div>
    <div class="popup-overlay">
      <span></span>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main";

export default {
  name: "Popup",
  props: {
    closePopupEvent: {
      type: Function
    },
    width: {
      type: String
    },
    top: {
      type: String
    },
    displayBack: {
      type: Boolean
    },
    heightPopup: {
      type: String
    },
    floater:{
      type: Boolean
    },
    propStyle:{
      type:Object,
      default(){
        return {};
      }
    },
    backgroundStyle:{
      type:Object,
      default(){
        return {};
      }
    }
  },
  // data(){
  //   return {
  //     heightPopup: "80%"
  //   }
  // },
  created() {
    EventBus.$on("popupMCQMountedEvent", this.popupMCQMountedCB);
    document.body.style.overflow = "hidden";
    // diable tab
    $(document).keydown(function(objEvent) {
      if (objEvent.keyCode === 9) {
        objEvent.preventDefault();
      }
    });
    if (!this.heightPopup) {
      this.height = "80%";
    } else {
      this.height = this.heightPopup;
    }
  },
  methods: {
    popupMCQMountedCB(state) {
      if (!state) return;

      let bodyHeight = document.body.clientHeight;
      let popupBox = this.$refs.popupBox;
      let popupBoxHeight = popupBox.offsetHeight;

      if (popupBoxHeight > bodyHeight) {
        popupBox.style.maxHeight = "80%";
        popupBox.style.overflow = "scroll";
      }
    },

    actBack() {
      EventBus.$emit("backPopup", true);
    }
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
    EventBus.$off("popupMCQMountedEvent", this.popupMCQMountedCB);
  }
};
</script>

<style lang="scss">
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";

.vlt-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $clr-dark-hlt;
  z-index: 8899;
  overflow-y: auto;
  overflow-x:hidden;
  display:flex;
  flex-flow:row wrap;
  justify-content: center;
  align-items: center;
  .popup-box {
    // position: fixed;
    position: relative;
    background-color: $clr-bg-gray-dark;
    //z-index: 99999;
    border-radius: 10px;
    min-width: 320px;
    margin:5vw 0;
    // top: 50%;
    // left: 50%;
    height:max-content;
    //transform: translate(-50%, -50%);
    // height: 80%;
    //overflow: auto;
  }
  .box-header {
    padding: 10px 15px;
    h6 {
      text-align: center;
      width: 100%;
      float: left;
      font-style: normal;
      color: $font-clr-white;
      font-size: 1.5rem;
      font-weight: $font-weight-semibold;
      font-family: $font-family;
    }
    .back-img {
      cursor: pointer;
    }
    .header-close {
      position: absolute;
      cursor: pointer;
      float: right;
      width: 34px;
      top: 12px;
      right: 2%;
      opacity: 0.7;
      @include closeIconHover;
    }
  }
  .popup-message {
    text-align: center;
    margin: 1em;
    display: block;
  }
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px)
@include breakpoint(max600) {
  .vlt-popup {
    .popup-box {
      min-width: 210px;
      width: 85% !important;
      //top: 50%;
    }
    .box-header {
      padding: 10px;
      h6 {
        font-size: 15px;
      }
      .header-close {
        width: 25px;
        top: 8px;
      }
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) and (max-width: 992px) {
@include breakpoint(min600max980) {
  .vlt-popup {
    .popup-box {
      min-width: 500px;
    }
  }
}
</style>